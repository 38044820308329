import { FC } from 'react';
import IconProps from './IconProps';

const ExternalLinkIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.875 7.625C11.4273 7.625 11.875 7.17728 11.875 6.625C11.875 6.07272 11.4273 5.625 10.875 5.625V7.625ZM18.375 13.125C18.375 12.5727 17.9273 12.125 17.375 12.125C16.8227 12.125 16.375 12.5727 16.375 13.125H18.375ZM14.125 4C13.5727 4 13.125 4.44772 13.125 5C13.125 5.55228 13.5727 6 14.125 6V4ZM19 6C19.5523 6 20 5.55228 20 5C20 4.44772 19.5523 4 19 4V6ZM20 5C20 4.44772 19.5523 4 19 4C18.4477 4 18 4.44772 18 5H20ZM18 9.875C18 10.4273 18.4477 10.875 19 10.875C19.5523 10.875 20 10.4273 20 9.875H18ZM19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L19.7071 5.70711ZM10.1679 12.4179C9.77737 12.8084 9.77737 13.4416 10.1679 13.8321C10.5584 14.2226 11.1916 14.2226 11.5821 13.8321L10.1679 12.4179ZM10.875 5.625H7.625V7.625H10.875V5.625ZM7.625 5.625C6.17525 5.625 5 6.80025 5 8.25H7C7 7.90482 7.27982 7.625 7.625 7.625V5.625ZM5 8.25V16.375H7V8.25H5ZM5 16.375C5 17.8248 6.17526 19 7.625 19V17C7.27981 17 7 16.7202 7 16.375H5ZM7.625 19H15.75V17H7.625V19ZM15.75 19C17.1998 19 18.375 17.8248 18.375 16.375H16.375C16.375 16.7202 16.0952 17 15.75 17V19ZM18.375 16.375V13.125H16.375V16.375H18.375ZM14.125 6H19V4H14.125V6ZM18 5V9.875H20V5H18ZM18.2929 4.29289L10.1679 12.4179L11.5821 13.8321L19.7071 5.70711L18.2929 4.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
